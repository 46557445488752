import { auth } from "../providers/firebase";
import React from "react";
import "./Login.css"
import { toast } from "react-toastify";
import Logo from "../images/LAE.png"
import BackgroundImage from "../images/loginFondo.jpg"
import { Link } from "react-router-dom";

class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      email: "",
      password: ""
    }
  }

  login(e) {
    e.preventDefault()
    auth.signInWithEmailAndPassword(this.state.email, this.state.password).then(() => window.location.href = "/").catch(err => {
      switch (err.code) {
        case "auth/invalid-email":
          toast.error("El formato del correo electrónico es incorrecto")
          break;
        case "auth/user-disabled":
          toast.error("La cuenta ha sido deshabilitada por el administrador")
          break;
        case "auth/user-not-found":
          toast.error("El usuario no existe")
          break;
        case "auth/wrong-password":
          toast.error("Contraseña incorrecta")
          break;
        default:
          toast.error("Error desconocido")
          break;
      }
    })
  }
  render() {
    const fechaLimite = new Date("2026-03-21T19:00:00");
    const fechaActual = new Date();
    return (
      <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
        <form className="loginForm" onSubmit={e => this.login(e)}>
          <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
          {fechaActual > fechaLimite ? (
            <div style={{ background: 'white', color: 'black', fontSize: '20px', textAlign: 'center'}}>
              Actualmente nos encontramos en mantenimiento
            </div>
          ) : (
            <>
              <input type="email" required value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Correo electrónico" />
              <input type="password" required minLength="6" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} placeholder="Contraseña" />
              <button type="submit">Iniciar Sesión</button>
              <Link to="/cambiarPassword" target="_blank">
                <div style={{
                            backgroundColor: "rgb(255, 255, 255)",
                            color: "rgb(48, 50, 72)",
                            margin: "10px auto 0",
                            padding: "8px 30px 0",
                            fontWeight: "normal",
                            fontSize: "10px"
                      }}>¿Has olvidado tu contraseña?
                </div>
              </Link>
            </>
          )}
        </form>
      </div>
    )
  }
}

export default Login;
